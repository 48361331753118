export default [
  {
    path: '/',
    redirect: 'home',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: '首页',
          icon: 'ic_nav_homepage_def'
        }
      },
      {
        path: '/authorize',
        component: () => import('@/views/authorize/Index.vue'),
        meta: {
          title: '授权管理',
          icon: 'ic_nav_authorize_def'
        },
        children: [
          {
            path: '/authorize/query',
            name: 'AuthQuery',
            component: () => import('@/views/authorize/AuthQuery.vue'),
            meta: {
              title: '授权查询'
            }
          },
          {
            path: '/authorize/add',
            name: 'AuthAdd',
            component: () => import('@/views/authorize/AuthAdd.vue'),
            meta: {
              title: '新建授权'
            }
          },
          {
            path: '/authorize/update',
            name: 'AuthUpdate',
            component: () => import('@/views/authorize/AuthUpdate.vue'),
            meta: {
              title: '授权更新'
            }
          }
        ]
      },
      {
        path: '/algorithm',
        component: () => import('@/views/algorithm/Index.vue'),
        meta: {
          title: '算法管理',
          icon: 'ic_nav_algorithm_def'
        },
        children: [
          {
            path: '/algorithm/warehouse',
            name: 'AlgorithmWarehouse',
            component: () => import('@/views/algorithm/AlgorithmWarehouse.vue'),
            meta: {
              title: '算法仓库'
            }
          },
          {
            path: '/algorithm/set',
            name: 'AlgorithmSet',
            component: () => import('@/views/algorithm/AlgorithmSet.vue'),
            meta: {
              title: '算法集管理'
            }
          }
        ]
      },
      {
        path: '/plantManage',
        name: 'PlantManage',
        component: () => import('@/views/plant/PlantManage.vue'),
        meta: {
          title: '工厂管理',
          icon: 'ic_nav_factory_def'
        }
      },
      {
        path: '/user',
        component: () => import('@/views/user/Index.vue'),
        meta: {
          title: '用户管理',
          icon: 'ic_nav_user_def',
          isAdmin: true
        },
        children: [
          {
            path: '/user/manage',
            name: 'UserManage',
            component: () => import('@/views/user/UserManage.vue'),
            meta: {
              title: '账户管理',
              isAdmin: true
            }
          },
          {
            path: '/user/org',
            name: 'Organization',
            component: () => import('@/views/user/Organization.vue'),
            meta: {
              title: '组织架构',
              isAdmin: true
            }
          }
        ]
      },
      {
        path: '/sys',
        component: () => import('@/views/sys/Index.vue'),
        meta: {
          title: '系统管理',
          icon: 'ic_nav_system_def'
        },
        children: [
          {
            path: '/sys/log',
            name: 'Log',
            component: () => import('@/views/sys/Log.vue'),
            meta: {
              title: '操作日志'
            }
          },
          {
            path: '/sys/about',
            name: 'About',
            component: () => import('@/views/sys/About.vue'),
            meta: {
              title: '关于'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    hidden: true
  }
]
