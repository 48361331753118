import { mapUrl } from '@/utils'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: '',
    isAdmin: false,
    setAdminFlag: false, // 是否设置过isAdmin
    title: '', // 系统名称
    logoUrl: '' // 系统Logo
  },
  getters: {},
  mutations: {
    initUserId(state, data) {
      state.userId = data
    },
    initAdmin(state, data) {
      state.isAdmin = data
    },
    setAdminFlag(state, data) {
      state.setAdminFlag = data
    },
    setTitle(state, data) {
      state.title = data
    },
    setLogo(state, data) {
      state.logoUrl = mapUrl(data)
    }
  },
  actions: {},
  modules: {}
})
