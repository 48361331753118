import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import merge from 'lodash/merge'
import qs from 'qs'
import config from '@/config'
import Message from '@/utils/resetMessage'
import { clearLoginInfo } from '@/utils'

/**
 * 创建axios实例
 */
const httpRequset = axios.create({
  timeout: 1000 * 30, // 请求超时时间
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

/**
 * 给实例赋值静态方法
 */
httpRequset.all = axios.all
httpRequset.spread = axios.spread

/**
 * 请求拦截
 */
httpRequset.interceptors.request.use(
  (config) => {
    // loading.open()
    // config配置
    if (!config.headers.notToken) {
      // 部分请求不设置token
      config.headers.Authorization = Vue.cookie.get('token') // 请求头带上token
    }
    return config
  },
  (error) => {
    // 处理出错
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
httpRequset.interceptors.response.use(
  (response) => {
    try {
      // response正常返回
      const res = response.data || {}
      // 校验token
      if (res.code === 1001) {
        // token 失效
        clearLoginInfo()
        router.push('/login')
      }
      return res
    } catch (error) {
      console.log(error)
    }
  },
  (error) => {
    // 处理response出错
    if (error && error.response) {
      switch (error.response.status) {
        // 401: token失效
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 401:
          Message.error('登录过期，请重新登录')
          clearLoginInfo()
          router.push('/login')
          break

        default:
          Message.error('服务器开小差了，请稍后再试')
          break
      }
    } else {
      Message.error('连接服务器失败')
    }

    return Promise.reject(error)
  }
)

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
httpRequset.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  return config.baseURL + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
httpRequset.adornParams = (params = {}, openDefultParams = true) => {
  const defaults = {
    t: new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
httpRequset.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  const defaults = {
    t: new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default httpRequset
