import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from '@/router/routers'
import store from '@/store'

Vue.use(VueRouter)

const routes = routers

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const { fullPath, meta } = to
  if (Vue.cookie.get('token')) {
    // 有token
    if (fullPath === '/login') {
      // 无需再次登录，跳转首页
      next('/home')
    } else {
      const { isAdmin } = meta || {}
      // 限制菜单权限
      if (store.state.setAdminFlag && isAdmin && isAdmin !== store.state.isAdmin) {
        next('/home')
      } else {
        next()
      }
    }
  } else {
    // 无token, 跳登录页
    if (fullPath === '/login') {
      // 避免重复触发
      next()
    } else {
      next('/login')
    }
  }
})

export default router
