<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// 修改页面title
export default {
  computed: {
    systemTitle() {
      return this.$store.state.title
    }
  },
  watch: {
    systemTitle(val) {
      document.title = val
    }
  }
}
</script>
