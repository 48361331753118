import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookie'
import HttpRequest from '@/utils/httpRequest'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import myMessage from '@/utils/resetMessage'
import '@/assets/theme/index.css'
import '@/assets/global.css'

Vue.use(ElementUI)
Vue.use(VueCookie)
Vue.prototype.$http = HttpRequest
// 注意：挂载自定义$message必须放在Vue.use(ElementUI)后面，才能覆盖element-ui默认的message，不然没有效果
Vue.prototype.$message = myMessage
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
