import Vue from 'vue'

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  Vue.cookie.delete('token')
}

/**
 * 映射url
 */
export function mapUrl(url) {
  return url
    ? process.env.NODE_ENV === 'development'
      ? url
      : location.origin + url.substr(findStrIndex(url, '/', 3))
    : ''
}

/**
 * 查找字符第几次出现的下标
 * @param {*} str 字符串
 * @param {*} cha 字符
 * @param {*} num 第几次出现
 */
export function findStrIndex(str, cha, num) {
  let index = str.indexOf(cha)
  for (let i = 0; i < num - 1; i++) {
    index = str.indexOf(cha, index + 1)
  }
  return index
}
